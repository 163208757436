<template>
    <v-container center>
        <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>
        <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>

        <v-row justify="center">
            <v-col cols="8" sm="6" md="6" lg="3">
                <v-img class="white--text align-end" src="../assets/logo-paposlandia-by-zapateria-letty.png">
                </v-img>

            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col cols="12" sm="12" md="6" lg="4">

                <v-card>
                    <v-card-title primary-title>Iniciar sesión
                        <v-spacer></v-spacer>
                              <v-chip
                                
                                label
                                
                                >
                                <v-icon left>mdi-tag-outline</v-icon>
                                v.{{ version }}
                                </v-chip>

                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="username" label="Usuario" required></v-text-field>

                        <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'" label="Contraseña"
                            @click:append="showPassword = !showPassword"
                            @keydown.enter="onSubmit"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="onSubmit" block>
                            Entrar
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>



    </v-container>
</template>

<script>
    import ApiService from '@/services/ApiService.js'
    import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
    import DialogEspera from '@/components/DialogEspera.vue'

    export default {
        name: 'Login',
        components: {
            SnackbarMensajes,
            DialogEspera
        },
        data() {
            return {
                dialogEspera: false,
                sb: {
                    mostrar: false,
                    mensaje: '',
                    color: 'success',
                    timeout: 3000,
                    multiline: false,
                },

                showPassword: false,
                username: '',
                password: ''
            }
        },
        computed: {
            version() {
                return this.$store.getters.version
            }
        },
        mounted() {
            // if( this.$store.getters.isAuthenticated ) {
            //     this.$router.replace('/')
            // }
        },
        methods: {
            showMensaje(mensaje,color,multiline) {
                this.sb.mensaje = mensaje
                this.sb.multiline = multiline
                this.sb.color = color
                this.sb.mostrar = true
            },
            onSubmit() {
                this.dialogEspera = true;
                ApiService.authUser({ login: this.username, password: this.password })
                    .then(response => {
                        // 200 => OK verified
                        if (response.status == 200) {
                            this.$store.dispatch('signIn', {userId: response.data.id, userName: response.data.username, accessToken: response.data.access_token, rbac: response.data.rbac})
                        } else {
                            this.showMensaje(`Usuario y/o contraseña incorrectos`,'error', true)
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 404 || error.response.status == 403) {
                            this.showMensaje(`Usuario y/o contraseña incorrectos`,'error', true)
                        }else this.showMensaje(
                            `Error en servidor ${error.response.status} - ${error.response.statusText}`,
                            'error', true)
                    })
                    .finally(() => {
                        this.dialogEspera = false
                    })

            },
            reset() {
                this.username = ''
                this.password = ''
            }
        }
    }
</script>